



































































































































































































































































































































































































































































































































































import { CompanyContact } from '@/api/interfaces/companyContact'
import { Company } from '@/api/interfaces/company'
import { newCompanyContact } from '@/components/molecules/select-search/store/companyContact'
import { defineComponent } from '@vue/composition-api'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import cancelSaveDialog from '@/mixins/cancelSaveDialog.js'
import { mapFields } from 'vuex-map-fields'
import { RawLocation } from 'vue-router'
import { isReadonly } from '@/composables/UserGrant'
import SelectSearch from '@/components/molecules/m-select-search.vue'
import SelectTrainingOfficer from '@/components/molecules/select/trainingOfficer.vue'
import { professionGestionnaire } from '@/config-constantes'
import codeNafs from '@socialgouv/codes-naf'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
      codeNafs,
    }
  },
  name: 'CompanyEdit',
  mixins: [cancelSaveDialog],
  components: {
    SelectTrainingOfficer,
    SelectSearch,
    CancelUpdateDialog: () =>
    import('../../../components/cancel-update-dialog.vue'),
    CreateEditCompanyContact: () =>
    import('../../../components/molecules/dialog/createEditCompanyContact.vue'),
    ScrollTop: () => import('@/components/base/ScrollTop.vue'),
    TopBar: () => import('@/components/organisms/o-top-bar.vue'),
    SelectCompanies: () =>
    import('@/components/molecules/select-search/company.vue'),
    PhoneNumber: () => import('@/components/molecules/input/phone-number.vue'),
    SelectCompanyAccountType: () => import('@/components/molecules/select/companyAccountType.vue'),
  },
  data () {
    return {
      title: this.$t('company.title'),
      companyContactEdit: {},
      companyContact: (newCompanyContact() as unknown) as CompanyContact,
      clientTypesList: ['Professionnels', 'Particuliers'],
      companyTypesList: ['SA', 'SARL', 'EURL', 'SAS'],
      createEditCompanyContactDialog: false,
      companyContactTypeManager: professionGestionnaire,
      headersCompanyContacts: [
        { value: 'firstName', text: this.$t('form.firstName') },
        { value: 'lastName', text: this.$t('form.lastName') },
        { value: 'phoneNumber', text: this.$t('form.phone') },
        { value: 'secondPhoneNumber', text: this.$t('form.secondPhone') },
        { value: 'email', text: this.$t('form.mail') },
        { value: 'profession.label', text: this.$t('form.profession') },
        {
          text: this.$t('company.list.headers.actions'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('companyForm', {
      company: 'company',
      isCompanySubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    ...mapState('trainingOfficer', {
      listTrainingOfficersDriverProgram: 'listTrainingOfficersDriverProgram',
      listTrainingOfficersOthers: 'listTrainingOfficersOthers',
      listCommercial: 'listCommercial',
      loadingTrainingOfficersDriverProgram: 'loadingTrainingOfficersDriverProgram',
      loadingTrainingOfficersOthers: 'loadingTrainingOfficersOthers',
      loadingCommercial: 'loadingCommercial',
    }),
    ...mapFields('companyForm', [
      'company.id',
      'company.name',
      'company.nameComplementary',
      'company.city',
      'company.companyContacts',
      'company.address',
      'company.clientType',
      'company.type',
      'company.zipCode',
      'company.phoneNumber',
      'company.secondPhoneNumber',
      'company.email',
      'company.website',
      'company.siret',
      'company.businessIntroducer',
      'company.commentary',
      'company.parentCompany',
      'company.accountType',
      'company.trainingOfficersDriverProgram',
      'company.trainingOfficersOthers',
      'company.commercials',
      'company.relationClient',
      'company.bankAccount',
      'company.paymentPeriod',
      'company.fleetSize',
      'company.nafCode',
    ]),
    isEdit () {
      return !!this.$route.params.idCompany
    },
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
  },
  async created () {
    await this.init()
  },
  watch: {
    $route () {
      this.init()
    },
  },
  methods: {
    async init () {
      const idCompany = this.$route.params.idCompany
      if (idCompany && this.isEdit) {
        this.reset()
        this.resetCompanyContacts()
        await this.loadCompanyById(idCompany)
        await Promise.all([
          this.loadCompanyContacts(this.company),
          this.loadTrainingOfficersDriverProgram({ pagination: false }),
          this.loadTrainingOfficersOthers({ pagination: false }),
          this.loadCommercials({ pagination: false }),
        ])
      } else {
        if ((this.company as Company).id) {
          // case user access directly to the form
          this.reset()
          this.resetCompanyContacts()
        }
        await Promise.all([
          this.loadTrainingOfficersDriverProgram({ pagination: false }),
          this.loadTrainingOfficersOthers({ pagination: false }),
          this.loadCommercials({ pagination: false }),
        ])
      }
    },
    ...mapActions('companyContactList', {
      loadCompanyContacts: 'load',
      resetCompanyContacts: 'reset',
      post: 'post',
    }),
    ...mapMutations('companyForm', {
      addCompanyContact: 'addCompanyContact',
    }),
    ...mapActions('companyForm', {
      loadCompanyById: 'loadById',
      save: 'save',
      reset: 'reset',
      setMainManager: 'setMainManager',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapActions('trainingOfficer', {
      loadTrainingOfficersDriverProgram: 'loadTrainingOfficersDriverProgram',
      loadTrainingOfficersOthers: 'loadTrainingOfficersOthers',
      loadCommercials: 'loadCommercials',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(
        this.$refs.validationObserver,
      )
      if (isValidatedForm) {
        await this.save()
        this.sendSuccessMessage('company.form.saved')
        this.returnPrevious()
      }
    },
    cancel () {
      this.returnPrevious()
    },
    returnPrevious () {
      if (this.getLastSavedRoute) {
        this.$router.push(this.getLastSavedRoute as RawLocation)
      } else {
        this.$router.push({ name: 'Company List' })
      }
    },
    close () {
      this.createEditCompanyContactDialog = false
      this.companyContactEdit = {}
    },
    addNewCompanyContact (companyContact: CompanyContact) {
      this.addCompanyContact(companyContact)
    },
    replaceCompanyContact (companyContact: CompanyContact, companyContactToReplace: CompanyContact) {
      if ((this.company as Company).companyContacts) {
        ((this.company as Company).companyContacts as Array<CompanyContact>).find(
          (x: CompanyContact, key: number) => {
            if (JSON.stringify(x) === JSON.stringify(companyContactToReplace)) {
              this.$set(
              (this.company as Company)?.companyContacts as Array<CompanyContact>,
              key,
              companyContact,
              )
              return x
            }
          },
        )
      }
    },
  },
})
